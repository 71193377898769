import React, {useState, useEffect, useContext} from 'react';
import AppBar from '@mui/material/AppBar';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
//import MenuList from '@mui/material/MenuList';
//import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { getAuth, signOut, onAuthStateChanged} from 'firebase/auth';
import { useNavigate} from 'react-router-dom';
import {useSharedState} from '../store';
import serverPost from '../services/serverPost'
import serverFetch from '../services/serverFetch';
import {search, search2} from '../services/search'

// AppBar
export default props => {
  const [sharedState, setSharedState] = useSharedState();
  const [email, setEmail] = useState(undefined)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate()
  const auth = getAuth()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = e => {
    setAnchorEl(null);
  };

  const handleReply = list => {
      if (list.length === 1) {
        let object = list[0]
        setSharedState({...sharedState, ...object})
      } else {
        alert('[AppBar] ERROR: Failed to set sharedState. Number of record in settings is ' + list.length + ' and should be 1')
      }  
  }
  
  useEffect(()=>{
    onAuthStateChanged(auth, user => {
      setEmail(user.email)
    })
    search('tbl_settings', {id:1}, handleReply) 
  }, [])

  const handleClickLine = (route, pageName) => {
    if (sharedState.modified) {
        if (!window.confirm('Vill du lämna sidan utan att spara data ?')) {    
           return
        } else {
          setSharedState({...sharedState, modified:undefined, pageName})
        }  
    } else {
        setSharedState({...sharedState, pageName})
    }  
    navigate(route)
  }

  const handleSignout = () => {
    setEmail(undefined)
    signOut(auth)
    navigate('/signin')
    window.location.reload()
  }

  const handleSignin = () => {
    navigate('/signin')
  }

  const handleUnlockSrCallback = (reply, id) => {
      
      const {status, message} = reply
      if (status === 'OK') {
        navigate('/sr/' + id)
        window.location.reload()
      } else {  
        alert('FELMEDDELANDE: Servicerapporten kunde inte uppdateras')
      }    
  }        

  const handleUnlockUtCallback = (reply, id) => {
      
    const {status, message} = reply
    if (status === 'OK') {
      navigate('/ut/' + id)
      window.location.reload()
    } else {  
      alert('FELMEDDELANDE: Servicerapporten kunde inte uppdateras')
    }    
}        

  const handleUnlockSr = () => {
    const tableName = 'tbl_service'
    const ans = prompt('Ange inlämningsnummer')
    const id = parseInt(ans)
    if (id > 0 ) {
      serverPost('/updateRow', '', '', {tableName, record:{disabledSaveSr:0}, id}, reply=>handleUnlockSrCallback(reply, id))
    }
  }

  const handleUnlockUt = () => {
    const tableName = 'tbl_service'
    const ans = prompt('Ange inlämningsnummer')
    const id = parseInt(ans)
    if (id > 0 ) {
      serverPost('/updateRow', '', '', {tableName, record:{disabledSaveUt:0}, id}, reply=>handleUnlockUtCallback(reply, id))
    }
  }

  const handleSenaste = () => {
      navigate('/in/latest')
  }

  const handleSearch = () => {
    navigate('/search')
  }

  const system = process.env.REACT_APP_ENVIRONMENT==='development'?'DEV':process.env.REACT_APP_ENVIRONMENT==='test'?'TEST':'PROD'
  const color = system !== 'PROD'?'yellow':'#FFFFFF'

  return (
    <div style={{position:'fixed', width:'100vw', top:0, left:0}}>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {email?<>
        <MenuItem onClick={()=>handleClickLine('/installningar', 'Inställningar')}>Inställningar</MenuItem>
        <MenuItem onClick={()=>handleUnlockSr()}>Lås upp servicerapport</MenuItem>
        <MenuItem onClick={()=>handleUnlockUt()}>Lås upp utlämning</MenuItem>
        <MenuItem onClick={()=>handleSenaste()}>Senaste inlämning</MenuItem>
        <MenuItem onClick={()=>handleSignout()}>Logga ut</MenuItem>
        </>
      :
        <MenuItem onClick={()=>handleSignin()}>Logga in</MenuItem>
      }
    </Menu>

    <Box sx={{ flexGrow: 2}}>
      <AppBar position="static" sx={{color:'#FFFFFF',  backgroundColor:'rgba(32,32,32,1)'}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color={'inherit'}
            aria-label="menu"
            sx={{color, mr: 0 }}
          >
            <HomeIcon 
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={()=>handleClickLine('/home', '')}
            />
          </IconButton>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1}}  onClick={()=>handleClickLine('/home', '')}>
          </Typography>

          <Typography variant="h4" component="div" sx={{ color, flexGrow: 4}}  onClick={()=>handleClickLine('/home', '')}>
            {sharedState.pageName}
          </Typography>
          
            {system !== 'PROD'?
              <Typography variant="h5" component="div" sx={{fontSize:'large', color, flexGrow: 1 }}  onClick={()=>handleClickLine('/home', '')}>
                  {system}
              </Typography>
            :null}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ color, mr: 0 }}
            onClick={handleClick}
            >
            <MenuIcon 
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
    </div>
  );
}

/*
        <MenuItem onClick={()=>handleSearch()}>Sökning</MenuItem>
*/